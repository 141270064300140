import { useEffect, useState } from 'react';
import axios from 'axios';
import './App.css';

function App() {

  const [loading, setLoading] = useState(false);
  const [codeFound, setCodeFound] = useState(false);

  function setCookie(name, value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }

  const getAccessToken = async () => {
    setLoading(true);
    // Get the query string from the URL
    const queryString = window.location.search;

    // Parse the query string to extract the value of the "code" parameter
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get('code');

    if (code !== null && code !== undefined && code !== '') {
      setCodeFound(true);
      console.log('The authorization code is:', code);

      try {
        let response = await axios.post(`https://social-sense-backend.onrender.com/auth/login?code=${code}`);
        let {status , access_token, firebase_id} = response.data;
        console.log("access_token => ", access_token);
  
        setCookie("access_token", access_token, 7);
        setCookie("firebase_id", firebase_id, 7);

        if(access_token !== null && access_token !== undefined && access_token !== '' && status === "Success") {
          window.location.href = "https://linkedin.com";
        }

      } catch (error) {
        console.log("Failed to get access token => ", error?.response?.data);
      }
    }

    setLoading(false);
  }

  useEffect(() => {
    getAccessToken();
  }, []);

  return (
    <div className="App">
      {
        loading ?
          <p>Loading...</p>
          :
          codeFound ?
            <p>Signing up...</p>
            :
            <p>Code not found!</p>
      }
    </div>
  );
}

export default App;
